import { queryOptions } from '@tanstack/vue-query'
import { useApi } from '~/domains/global/composables/useApi'

export const listPaymentsQuery = () => {
  return queryOptions({
    queryKey: ['payments'],
    queryFn: async () => {
      const api = useApi()
      const data = await api<PaymentData[]>(`/users/payments`, {
        method: 'GET',
      })

      return data
    },
    staleTime: 1000 * 60 * 60,
  })
}

export enum PaymentType {
  PIX = 'PIX',
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum PaymentState {
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  PROCESSING = 'PROCESSING',
  AUTHORIZED = 'AUTHORIZED',
  PAID = 'PAID',
  REFUNDED = 'REFUNDED',
  PENDING_REFUND = 'PENDING_REFUND',
  CHARGEBACK = 'CHARGEBACK',
  PENDING_REVIEW = 'PENDING_REVIEW',
}

export type PaymentData = {
  id: string
  amount: number
  installments: number
  type: PaymentType
  state: PaymentState
  pixQrcode: string
  cpf: string
  userId: string
  paymentRecoveryProcessed: boolean
  affiliateComission: number
  planMonths: number
  affiliateId: string
  createdAt: string
  updatedAt: string
}
